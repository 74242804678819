@import '../../constants/variables.scss';

.testDatePicker {
  display: flex;
  flex-direction: row;
  select {
    font-size: $standard;
    width: 200px; 
    margin-right: 20px;
  }
}