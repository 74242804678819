@import '../../constants/variables.scss';

.button {
  box-sizing: border-box;
  background: $buttonColorA1;
  color: $buttonColorA2;
  border: 1px solid $buttonColorA2;
  border-radius: $borderRadius;
  font-size: $standard;
  font-weight: $semibold;
  outline: none;
  padding: 10px 20px;
  text-transform: uppercase;
  
  &:hover {
    cursor: pointer;
    font-weight: $bold;
    background: $buttonColorA2;
    color: $buttonColorA1;
    border-color:  $buttonColorA1;
  }

  &:disabled {
    cursor: default;
    background: $grayLight;
    color: $grayDark;
    border-width: 1px;
  }
}

.button.cta {
  background: $buttonColorA2;
  color: $buttonColorA1;
  border-color:  $buttonColorA1;

  &:hover {
    background: $buttonColorA1;
    color: $buttonColorA2;
    border-color:  $buttonColorA2;
  }
}
