@import '../../constants/variables.scss';

.simpleHeader {
  padding: $defaultPadding;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  // min-height: 88px;
  font-family: 'proxima-nova', sans-serif;

  img {
    width: 145px;
    height: 40px;
  }
  a {
    font-size: $smallHeader;
    color: $primary;
    font-weight: $semibold;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
