@import '../../constants/variables.scss';

.testSubjectRating {
  display: flex;
  flex-direction: row;
  padding: 5px;

  .title {
    font-size: $standard;
    padding: 10px 0;
    width:70px;
    text-transform: capitalize;
  }
  .buttons {
    display: flex;
    flex-direction: row;
  
  }
  
}