@import '../../constants/variables.scss';

.resultsMeetings {
  display: flex;
  flex-direction: column;
}
.programInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: $secondary;
  font-size: $header;
  font-weight: $semibold;
}
.meetingInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // padding: 20px 0;
  margin-bottom: 20px;
  color: $secondary;
  font-size: $standard;
  font-weight: $semibold;
}

.number {
  // color: $secondary;
  font-size: $title;
  font-weight: $bold;
  padding-right: 10px;
}