@import '../../constants/variables.scss';

.resultsDates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 20px 0;
  margin-bottom: 20px;
  color: $secondary;
  font-size: $standard;
  .spacer {
    margin: 0 5px;
  }
  .dateBlock {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dateLabel {
    font-weight: $semibold;
    margin-top: 5px;
  }
  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1.5px solid $grayDark;
    padding: 5px 3px;
    border-radius: $borderRadius;
    min-height: 100px;
    width: 100px;
    font-weight: $bold;
    .dateDay {
      font-size: $title;
      font-weight: $regular;
    }
    .dateYear {
      font-size: $smallHeader;
      color: $grayDark;
    }
  }
}