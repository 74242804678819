@import '../../constants/variables.scss';

.questionStartDate {
  display: flex;
  flex-direction: column;  
}
.startDate {
  display: flex;
}
.userResponse {
  display: flex;
  flex-direction: row;
  font-weight: $semibold;
  width: 200px;
  margin-left: 5px;
}
.editQuestion {
  display: flex;
  flex-direction: row;
  align-items: center;
}
