@import '../../constants/variables.scss';

$buttonFont: $smallHeader;
$buttonFontWeight: $semibold;
$buttonPadding: 10px 20px;;

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 10px 0;
  .buttons { 
    display: flex;
    flex-direction: row;
  }
  button {
    box-sizing: border-box;
    border-radius: $borderRadius;
    font-size: $buttonFont;
    font-weight: $buttonFontWeight;
    padding: $buttonPadding;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }
  button.checkout {
    background: $secondary;
    color: white;
    border: 1px solid white;
    margin-right: 5px;
    &:hover {
      background: white;
      color: $secondary;
      border-color:  $secondary;
    }
  }
  button.save {
    background: white;
    color: $secondary;
    border: 1px solid $secondary;
    &:hover {
      background: $secondary;
      color: white;
      border-color:  white;
    }

  }
}

.form {
  margin: 10px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  div {
    padding: 3px 0;
    display: flex;
    align-items: center;
  }
  label {
    padding-right: 5px;
    color: $secondary;
    text-transform: lowercase;
  }
  input {
    font-size: $standard;
    padding: 5px 3px;
    width: 300px;
  }
  input[type="submit"] {
    width: auto;
    box-sizing: border-box;
    border-radius: $borderRadius;
    font-size: $buttonFont;
    font-weight: $buttonFontWeight;
    padding: $buttonPadding;
    outline: none;

    background-color: $secondary;
    border: 1px solid $secondary;
    color: white;
    margin: 5px 0;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: $secondary;
    } 
  }
}

.success {
  margin-top: 10px;
  font-weight: bold;
}

.error {
  margin-top: 10px;
  color: $errorColor;
  font-weight: bold;
}