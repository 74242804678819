@import '../../constants/variables.scss';

.toggleButton {
  background: $buttonColorA2;
  color: $buttonColorA1;
  border-color:  $buttonColorA1;
  
  box-sizing: border-box;
  border-radius: $borderRadius;
  font-size: $standard;
  font-weight: $semibold;
  outline: none;
  padding: 10px 20px;
  text-transform: uppercase;
  margin: 0 2px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: $buttonColorA1;
  }
  
}

.toggleButton.selected {
  background: $buttonColorA1;
  color: $buttonColorA2;
  border: 1px solid $buttonColorA2;
}
