@import '../../constants/variables.scss';

.planBuilder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: $backgroundLight;
  width: 100%;
  box-sizing: border-box;
}
.results {
  overflow: scroll;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .planBuilder {
    display: flex;
    flex-direction: column;
    background: $backgroundLight;
    // width: 88%;
  }
  .results {
    height: initial;
  }
}