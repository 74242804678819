@import '../../constants/variables.scss';

.questionContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px;
  margin: 0 30px 30px;
  border-bottom: 1px solid $gray;

  .questionHeader {
    display: flex;
    flex-direction: row;
    font-size: $subheader;
    align-items: flex-start;
    margin-bottom: 20px;

  }
  .questionNumber {
    color: $grayDark;
    font-weight: $semibold;
    width: 35px;
  }
  .questionSubText {
    font-size: $standard;
    margin-bottom: 10px;
  }
  // .questionBody {
  //   padding: 0px 35px;;
  // }
}

@media screen and (max-width: 600px) {
  .questionContainer {
    margin: 0 0 30px 0;
  }
}