@import '../../constants/variables.scss';

.planQuestionSet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 65%;
  padding: $defaultPadding;
  box-sizing: border-box;
}
.title {
  font-size: $header;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .planQuestionSet {
    width: 100%;
  }
  .title {
    text-align: center;
  }
}