@import '../../constants/variables.scss';

.button {
  box-sizing: border-box;
  background: white;
  color: $primary;
  border: 1px solid white;
  border-radius: $borderRadius;
  font-size: $standard;
  font-weight: $semibold;
  outline: none;
  // padding: 5px 8px;
  text-transform: lowercase;
  max-width: 150px;
  &:hover {
    // background: $primary;
    // color: white;
    cursor: pointer;
    border: 1px solid $primary;
  }
  &:disabled {
    cursor: default;
    background: $grayLight;
    color: $grayDark;
    border-width: 1px;
  }
}

