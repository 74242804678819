@import '../../constants/variables.scss';

.priceContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  border: 1.5px solid $grayDark;
  border-radius: $borderRadius;
  margin-bottom: 20px;
  color: $secondary;
  font-size: $standard;
  font-weight: $semibold;
}

.totalCost, .monthlyCost, .dueToday {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  font-size: $standard;
  text-align: center;
}

.totalCost {
  color: $grayExtraDark;
}

.monthlyCost {
  border-left: 1px solid $grayDark;
  color: $secondary;
  padding-right: 20px;
}

.dueToday {
  color: $secondaryLight;
  border-right: 1px solid $grayDark;
  padding-left: 20px;
}

.number {
  font-weight: $bold;
  font-size: 28px;
}