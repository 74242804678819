@import '../../constants/variables.scss';

.planResults {
  display: flex;
  flex-direction: column;
  padding: $defaultPadding;
  box-sizing: border-box;
}
.fixed {
  position: fixed;
  // right: 0;
  top: 0;
}
.title {
  font-size: $header;
  font-weight: bold;
  margin-bottom: 20px;
}
.shortProgramContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid $secondaryLight;
  border-radius: $borderRadius;
  padding: 10px;
  background: $secondary;
  color: white;
}
.shortProgram {
  padding: 10px 0;
}
.callButton {
  box-sizing: border-box;
  border-radius: 16px;
  font-size: $subheader;
  outline: none;
  // padding: 10px 20px;
  padding: $defaultPadding;
  box-sizing: border-box;

  margin: 15px 0;
  background: white;
  color: $secondary;
  border: 1px solid white;
  // line-height: 40px;
  &:hover {
    cursor: pointer;
    color: $secondaryLight;
    border-color:  $secondaryLight;
  }
}

@media screen and (max-width: 600px) {
  .planResults {
    width: 100%;
    position: relative;
    border-top: 1.5px solid $secondary;
    padding-top: 25px;
    // background: $secondaryExtraLight;
  }
  .title {
    text-align: center;
  }
}