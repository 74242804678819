@import '../../constants/variables.scss';

.buttons {
  display: flex;
  flex-direction: row;
}
.testDateContainer {
  display: flex;
  flex-direction: column;
}
.testDateSelect {
  display: flex;
  flex-direction: row;
}
